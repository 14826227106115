/**
 * The abstract model
 * @author Evgeny Shevtsov, info@sitespring.ru
 * @homepage http://sitespring.ru
 * @licence Proprietary
 */
import {Model} from "vue-mc";
import {get, isArray, isEmpty, isObject, values} from "lodash";

export default class BaseModel extends Model {
    static entityName;
    static urlPrefix = "admin";


    /**
     * Helper to remove listener previously added by [on]
     * since vue-mc does not provide such method
     * */
    off(event) {
        delete this._listeners[event];
    }


    defaults() {
        return {
            id: null,
        }
    }

    options() {
        return {
            entityName: null,
            useFirstErrorOnly: true, // Whether this model should only use the first validation error it receives, rather than an array of errors.
            patch: true, // Whether this model should perform a “patch” on update (only send attributes that have changed).
            saveUnchanged: true, //	Whether this model should save even if no attributes have changed. If set to false and no changes have been made, the request will be considered a success.
            fetchParams: {
                fields: null,
                expand: null
            },
            autoLoad: false
        }
    }

    getRouteParameters() {
        return {
            ...super.getRouteParameters(),
            urlPrefix: this.getOption('urlPrefix') || this.constructor.urlPrefix || BaseModel.urlPrefix,
            entityName: this.getOption('entityName') || this.constructor.entityName
        }
    }

    mutations() {
        return {
            id: (id) => Number(id) || null
        }
    }

    routes() {
        return {
            fetch: `{urlPrefix}/{entityName}/{id}`,
            save: !this.isPhantom() ? `{urlPrefix}/{entityName}/{id}` : `{urlPrefix}/{entityName}`,
            delete: `{urlPrefix}/{entityName}/{id}`
        }
    }

    getSaveMethod() {
        return !this.isPhantom() ? "PUT" : "POST";
    }

    /**
     * @inheritDoc
     * */
    getFetchQuery() {
        return {
            ...this.getOption("fetchParams")
        }
    }


    /**
     * Whether the model has identifier or not
     * @return boolean
     * */
    isPhantom() {
        return !this.get(this.getOption('identifier'));
    }


    /**
     * Метод для парсинга ошибок из ответа сервера
     * @param {object} response
     * @return {object} Индексированный объект, где ключи название аттрибутов, а значения массив ошибок
     * */
    parseResponseValidationErrors(response) {
        let parsedErrors = [];

        /** В виде массива по классичекой схеме:
         * [
         *     {
         *          "field": "cottage_id",
         *          "message": "Необходимо заполнить «Идентификатор Коттеджа»."
         *     },
         *      ...
         * ]*/
        if (isArray(response.data) && response.data[0] && response.data[0]['message']) {
            // Собираем ошибки в индексированный объект
            parsedErrors = reduce(response.data, (result, value) => {
                result[value.field] = [value.message];
                return result;
            }, {});
        }

        /** Ожидаем от сервера ответ в формате:
         * Объекта: {
            email: [
                "Should be a valid email address",
            ],
        }
         **/
        else if (isObject(response.data)) {
            parsedErrors = response.data;
        }
        return parsedErrors;
    }


    /**
     *  Обработчик ошибок
     *  Добавляем в объект ошибки дополнительную информацию
     *
     *  @typedef {object} proxyHandledError Расширенный экземпляр ошибки
     *  @property {Array} parsedErrors Массив ошибок которые удалось распарсить
     *  @property {Boolean} isRemoteError Была ли ошибка на стороне сервера
     *  @property {Boolean} isValidationError Информирует что на стороне сервера не пройдена валидация
     *
     *  @return {proxyHandledError} Экземпляр ошибки
     *  */
    handleResponseError(error) {
        // По умолчанию текст ошибки
        error.parsedErrors = ['Неизвестная ошибка связи'];

        //handle validation errors here
        if (error.response) {
            error.isRemoteError = true;
            // В ответе есть сообщение
            const responseMessage = get(error, 'response.data.message') || get(error, 'response.message');
            if (responseMessage) {
                error.parsedErrors = [{0: responseMessage}];
            }

            // Ошибка валидации на стороне сервера
            if (error.response.status === 422) {
                // Отдаем ошибки валидации на откуп вьюшкам, передавая их в свойстве parsedErrors
                error.isValidationError = true;
                error.parsedErrors = this.parseResponseValidationErrors(error.response);
            }
        }
        return error;
    }


    /**
     * @return boolean
     * */
    hasErrors() {
        return !isEmpty(this.errors);
    }


    /**
     * @return ?string
     * */
    getFirstErrorMesage() {
        return values(this.errors)[0] || null;
    }
}