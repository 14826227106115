/**
 * The Base Store
 *
 * @author Evgeny Shevtsov, info@sitespring.ru
 * @homepage http://sitespring.ru
 * @licence Proprietary
 */
import {Collection} from "vue-mc";
import BaseModel from "./BaseModel";
import {Collection as VueCollection} from "vue-mc";
import isEqual from "lodash/isEqual";
import reduce from "lodash/reduce";
import map from "lodash/map";
import values from "lodash/values";
import isEmpty from "lodash/isEmpty";
import compact from "lodash/compact";
import size from "lodash/size";
import {isArray, union} from "lodash";

/**
 * @event filtersdrop
 * When all filters has been dropped
 * @param store BaseStore
 * */

/**
 * @event filterschange
 * When filter has been changed
 * @param store - The BaseStore
 * @param oldFilters - The object of filters
 * @param newFilters - The object of filters
 * */

/**
 * @event sortchange
 * When filter has been changed
 * @param store - The BaseStore
 * @param oldFilters - The object of filters
 * @param newFilters - The object of filters
 * */

export default class BaseStore extends Collection {
    static EVENT_FILTERSCHANGE = 'filterschange';
    static EVENT_FILTERSDROP = 'filtersdrop';
    static EVENT_SORTCHANGE = 'sortchange';
    static SORT_ASC = 'ASC';
    static SORT_DESC = 'DESC';

    defaults() {
        return {
            sorter: [],
            filters: {}
        }
    }


    /**
     * @inheritDoc
     * */
    constructor(models, options) {
        super(models, options);

        // set filters by default from option
        let filters = this.getOption("filters");
        if (filters) {
            this.setFilters(filters);
        }

        // set sorter by default from option
        let sorter = this.getOption("sorter");
        if (sorter) {
            this.setSorter(sorter);
        }

        if (this.getOption("autoLoad")) {
            this.fetch();
        }

        this.on(BaseStore.EVENT_FILTERSCHANGE, this.onFiltersChange);
        this.on(BaseStore.EVENT_SORTCHANGE, this.onSorterChange);
    }


    /**
     * @inheritDoc
     * */
    options() {
        return {
            // allow pass fetch url via option whithout extend class
            fetchUrl: null,

            // The default pair of filterId => filterDefs to be handled as defaults in format
            // { property:'propName', operation:'eq|in|not in|...', value:'value'}
            internalFilters: {},

            // The startup filters to be set by {setFilters} method
            filters: {},

            // The internal list of sorters in the format
            // { property: 'propName', direction: 'ASC|DESC'}
            internalSorters: [],

            // The startup sorter in the format
            // { property: 'propName', direction: 'ASC|DESC'}
            sorter: {},

            // Fetch params
            fetchParams: {
                limit: 20
            },

            // Whether should be fetched while construct
            autoLoad: false,

            // Whether should be reloaded when sort has changed
            autoSort: false,

            // Whether should be reloaded when filters has changed
            autoFilter: false
        }
    }


    /**
     * @inheritDoc
     * */
    getRouteParameters() {
        return {
            ...super.getRouteParameters(),
            urlPrefix: this.getOption('urlPrefix') || this.getOption("model").urlPrefix || BaseModel.urlPrefix,
            entityName: this.getOption('entityName') || this.getOption("model").entityName
        }
    }


    /**
     * @inheritDoc
     * */
    routes() {
        return {
            fetch: this.getOption("fetchUrl") || `{urlPrefix}/{entityName}`,
            save: this.getOption("saveUrl") || `{urlPrefix}/{entityName}/batch`,
        }
    }


    /**
     * @inheritDoc
     * */
    getFetchQuery() {
        let fetchParams = {
            ...this.getPaginationQuery(),
            ...this.getOption("fetchParams")
        };

        // apply Filters
        let filters = this.getFiltersQueryString();
        if (filters.length > 0) {
            fetchParams.filter = filters;
        }

        //apply sorters
        let sorters = this.getSortersQueryString();
        if (sorters.length > 0) {
            fetchParams.sort = sorters;
        }
        return fetchParams;
    }


    /**
     * Helper to serialize filters object to queary param
     * @return string
     * */
    getFiltersQueryString() {
        let allFilters = {...this.getOption("internalFilters"), ...this.getFilters()};
        if (isEmpty(allFilters)) {
            return "";
        }
        return JSON.stringify(compact(values(allFilters)));
    }


    /**
     * Helper to serialize sorters object to queary param
     * @return string
     * */
    getSortersQueryString() {
        let internalSorters = this.getOption("internalSorters") || [];
        if (!isArray(internalSorters)) {
            internalSorters = [internalSorters];
        }

        let currentSorter = this.getSorter() || [];
        if (!isArray(currentSorter)) {
            currentSorter = [currentSorter];
        }

        let allSorters = [...internalSorters, ...currentSorter];
        if (isEmpty(allSorters)) {
            return "";
        }

        return map(allSorters, (sorter) => {
            let direction = sorter.direction && sorter.direction == BaseStore.SORT_DESC ? "-" : "";
            let property = sorter.property;
            return `${direction}${property}`;
        }).join(",");
    }


    /**
     * add util method to reload store from remote with current fetchParams
     * */
    reload(options) {
        if (this.loading) {
            return false;
        }
        this.clear();

        if (this.isPaginated()) {
            this.page(1);
        }
        return this.fetch(options);
    }


    /**
     * @inheritDoc
     * */
    getModelsFromResponse(response) {
        // Assume server collectionEnvelope is [data]
        return response.getData().data || [];
    }


    /**
     * @return int - The size of current filters
     * */
    getFiltersCount() {
        return size(this.getFilters());
    }

    /**
     * Helper to get all current filters
     * @return object|null - The current filters defs by [filterId => filterDefs]
     * */
    getFilters() {
        return {...this.filters};
    }

    /**
     * Helper to get filter by id
     * */
    getFilter(filterID) {
        return this.getFilters()[filterID];
    }


    /**
     * Helper to apply filter: set or remove depend on value
     * @param filterID string
     * @param filterDefs object
     * */
    applyFilter(filterID, filterDefs) {
        let filters = this.getFilters();
        let value = filterDefs.operator == 'null' || filterDefs.value || null;

        if (value && !isEqual([], value) && !isEqual({}, value)) {
            filters[filterID] = filterDefs;
            this.setFilters(filters);
            // console.log("Set filters", filters);
        } else {
            this.removeFilter(filterID);
            // console.log("Remove filter", filterID);
        }
    }


    /**
     * Helper to set filters
     * Filters set by filterId=>filterDefs pairs
     * @param object|null filters - Null mean drop filters
     * */
    setFilters(newFilters = {}) {
        let oldFilters = this.getFilters();
        if (!isEqual(oldFilters, newFilters)) {
            this.filters = newFilters;
            this.emit(BaseStore.EVENT_FILTERSCHANGE, this, oldFilters, newFilters);
        }
    }


    /**
     * Helper to remove filter from filters stack by id
     * */
    removeFilter(id) {
        let currentFilters = this.getFilters();
        let newFilters = {...currentFilters};
        delete newFilters[id];

        this.setFilters(newFilters);
    }


    /**
     * Helper to remove all filters
     * */
    dropFilters() {
        // Set internal filters by option default
        this.setFilters({});
        this.emit(BaseStore.EVENT_FILTERSDROP, this);
    }


    /**
     * When filters has changed
     * @param newFilters object
     * */
    onFiltersChange(newFilters) {
        let autoFilter = this.getOption("autoFilter");
        if (autoFilter) {
            this.reload();
        }
    }


    /**
     * When filters has changed
     * @param newFilters object
     * */
    onSorterChange(me, oldSorter, newSorter) {
        if (this.getOption("autoSort")) {
            this.reload();
        }
    }


    /**
     * Get current sorter applied to store, internal sorters are not handle
     * @return object|null
     * */
    getSorter() {
        return !isEmpty(this.sorter) ? {...this.sorter} : null;
    }


    /**
     * Helper to set sorters
     * Sorters set by sorterId=>sorterDefs pairs
     * @param object|null sorters - Null mean drop sorters
     * */
    setSorter(sorterDefs) {
        let oldSorter = this.getSorter();
        let newSorter = sorterDefs;

        if (!isEqual(oldSorter, newSorter)) {
            this.sorter = newSorter;
            this.emit(BaseStore.EVENT_SORTCHANGE, this, oldSorter, newSorter);
        }
    }


    /**
     * Helper to remove sorter
     * */
    dropSorter() {
        this.setSorter({});
    }

}